import './dialler.scss'

import { DialerControlButton } from 'components'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IMakeCall } from 'store/call/call.state'
import { setNumberDialling } from 'store/global/global.actions'
import { IEnabledQueue } from 'store/settings/settings.state'
import RootState from 'store/state'
import { getBodyWidth, removeInvalidCharacters } from 'utils'
import { HomeContext } from 'views/Home'
import QuickDialler from 'views/QuickDialler'

import { Form, TriggeredPopup } from '@missionlabs/smartagent-app-components'

import Keypad from 'components/Keypad'
import OutboundQueueSelector from './OutboundQueueSelector'
import SelectedOutboundQueue from './SelectedOutboundQueue'
import Answer from './buttons/Answer-Button.svg'
import KeypadBtn from './buttons/Keypad-Button.svg'

interface Props {
    makeCall: IMakeCall
    selectedOutboundQueue?: IEnabledQueue
    onOutboundQueueSelected?: (queue?: IEnabledQueue) => void
    isOutboundQueueSelectorEnabled?: boolean
}

const Controls = ({
    makeCall,
    selectedOutboundQueue,
    onOutboundQueueSelected,
    isOutboundQueueSelectorEnabled = false,
}: Props) => {
    const number = useSelector((state: RootState) => state.global.numberDialling)
    const user = useSelector((state: RootState) => state.user)

    const disabled = !user

    const numberInput = useRef(null)

    const dispatch = useDispatch()

    const hasFeature = useHasFeature()

    const showOutboundQueueSelector =
        hasFeature(AppFeatures.QUEUES_CLI_SELECTOR) && isOutboundQueueSelectorEnabled

    const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setNumberDialling(ev.target.value))
    }

    const onCall = () => {
        if (!number || disabled) return
        // remove invalid characters before making the call
        const cleanNumber = removeInvalidCharacters(number)

        makeCall(cleanNumber, undefined, undefined, selectedOutboundQueue?.resourceId)
    }

    const onKeypadButton = (val: string) => {
        dispatch(setNumberDialling(number + val))
    }

    const onKeypadBackspace = () => {
        dispatch(setNumberDialling(number.slice(0,-1)))
    }

    const handleOnOutboundQueueSelect = (queue: IEnabledQueue) => {
        onOutboundQueueSelected?.(queue)
    }

    const handleOnCloseButtonClick = () => {
        onOutboundQueueSelected?.(undefined)
    }

    return (
        <HomeContext.Consumer>
            {({
                keyPadOpened,
                setKeyPadOpened,
                setQuickConnectOpened,
                quickConnectOpened,
                isQueueListShown,
                setIsQueueListShown,
            }) => {
                return (
                    <>
                        <SelectedOutboundQueue
                            queueName={selectedOutboundQueue?.name}
                            onCloseButtonClick={handleOnCloseButtonClick}
                        />

                        <Form className="dialler-keypad" onSubmit={onCall}>
                            <input
                                ref={numberInput}
                                title="Enter number"
                                className="remote-number"
                                type="text"
                                placeholder="Enter number..."
                                value={number}
                                onChange={onChange}
                                onBlur={onChange} // For speech to text purposes, the onChange event is not triggered on input
                            />
                            <div className="dialler-controls">
                                {showOutboundQueueSelector && (
                                    <OutboundQueueSelector
                                        selectedOutboundQueue={selectedOutboundQueue}
                                        onOutboundQueueSelect={handleOnOutboundQueueSelect}
                                        isQueueListShown={isQueueListShown}
                                        onOpen={() => setIsQueueListShown?.(true)}
                                        onClose={() => setIsQueueListShown?.(false)}
                                    />
                                )}

                                <QuickDialler
                                    channel="VOICE"
                                    quickConnectOpened={quickConnectOpened}
                                    onOpen={() => setQuickConnectOpened?.(true)}
                                    onClose={() => setQuickConnectOpened?.(false)}
                                />

                                <TriggeredPopup
                                    closeOnClickOutside
                                    onOpen={() => {
                                        setKeyPadOpened?.(true)
                                    }}
                                    onClose={() => setKeyPadOpened?.(false)}
                                    noShadow
                                    fromCenter={getBodyWidth() > 500}
                                    className="dialler-popup"
                                    trigger={
                                        <DialerControlButton
                                            disabled={disabled}
                                            className="keypad white-button"
                                            isFocused={keyPadOpened}
                                            type="button"
                                            onClick={() => setKeyPadOpened?.(false)}
                                        >
                                            <img src={KeypadBtn} alt="Keypad" title="Keypad" />
                                        </DialerControlButton>
                                    }
                                >
                                    <Keypad onKeypadButton={onKeypadButton} onKeypadBackspace={onKeypadBackspace}/>
                                </TriggeredPopup>

                                <DialerControlButton disabled={disabled || !number} elastic>
                                    <img
                                        src={Answer}
                                        alt="Dial"
                                        title="Dial"
                                        width="40"
                                        height="40"
                                    />
                                </DialerControlButton>
                            </div>
                        </Form>
                    </>
                )
            }}
        </HomeContext.Consumer>
    )
}

export default Controls
