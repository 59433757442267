var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import React, { PureComponent, createElement, Component } from "react";
import classNames from "classnames";
import "react-player";
import "react-spinners";
import Button from "./Button.mjs";
import Checkbox from "./Checkbox.mjs";
import { F as FormConsumer } from "./context-jqLAdImf.mjs";
import Form from "./Form.mjs";
import Input from "./Input.mjs";
import "./Popup.mjs";
import ReactDatePicker from "./ReactDatePicker.mjs";
import Tooltip from "./Tooltip.mjs";
import Tag from "./Tag.mjs";
import TriggeredPopup from "./TriggeredPopup.mjs";
import "draft-js";
import "react-draft-wysiwyg";
import "./emoji-picker-l0sNRNKZ.mjs";
import ConditionalWrapper from "./ConditionalWrapper.mjs";
import { S as SUBMIT_KEYS } from "./index-BBDie59p.mjs";
const CrossIcon = "data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20width='8'%20height='8'%3e%3cdefs%3e%3cpath%20d='M14.862%208.195a.667.667%200%2001.943.943L12.943%2012l2.862%202.862c.234.234.257.6.07.86l-.07.083a.667.667%200%2001-.943%200L12%2012.943l-2.862%202.862a.667.667%200%2001-.86.07l-.083-.07a.667.667%200%20010-.943L11.057%2012%208.195%209.138a.667.667%200%2001-.07-.86l.07-.083c.26-.26.683-.26.943%200L12%2011.057z'%20id='a'/%3e%3c/defs%3e%3cuse%20fill='%23556272'%20fill-rule='nonzero'%20xlink:href='%23a'%20transform='translate(-8%20-8)'/%3e%3c/svg%3e";
const DropdownIcon = "data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20width='24'%20height='24'%3e%3cdefs%3e%3cpath%20d='M8.323%2013.668l3.686%203.686%203.702-3.573a.77.77%200%20011.093.026.77.77%200%2001-.012%201.092L12.547%2019a.768.768%200%2001-.965.08.855.855%200%2001-.186-.139l-4.172-4.172a.77.77%200%2001.007-1.093.77.77%200%20011.092-.007zm4.136-9.526l.088.072%204.245%204.1a.77.77%200%2001.012%201.092.772.772%200%2001-1.005.099l-.088-.073-3.701-3.575-3.687%203.688a.767.767%200%2001-1.005.068l-.087-.075a.77.77%200%2001-.007-1.093l4.172-4.172a.768.768%200%2001.185-.137l-.04.027a.77.77%200%2001.918-.021z'%20id='a'/%3e%3c/defs%3e%3cuse%20fill='%2369696e'%20fill-rule='nonzero'%20xlink:href='%23a'/%3e%3c/svg%3e";
const SearchIcon = "data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20width='22'%20height='22'%3e%3cdefs%3e%3cpath%20d='M9.656%201c4.772%200%208.656%203.883%208.656%208.656a8.604%208.604%200%2001-1.52%204.886l5.743%205.742A1.592%201.592%200%200121.41%2023a1.58%201.58%200%2001-1.124-.466l-5.742-5.742a8.604%208.604%200%2001-4.888%201.519C4.883%2018.311%201%2014.428%201%209.656%201%204.883%204.883%201%209.656%201zM9.6%203C5.96%203%203%205.96%203%209.598a6.606%206.606%200%20006.6%206.598%206.606%206.606%200%20006.597-6.598A6.605%206.605%200%20009.6%203z'%20id='a'/%3e%3c/defs%3e%3cuse%20fill='%23959DA7'%20fill-rule='nonzero'%20xlink:href='%23a'%20transform='translate(-1%20-1)'/%3e%3c/svg%3e";
const plusGreen = "data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20width='24px'%20height='24px'%20viewBox='0%200%2024%2024'%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!--%20Generator:%20Sketch%2060.1%20(88133)%20-%20https://sketch.com%20--%3e%3ctitle%3eGraphic%20/%20Icon%20/%20Toggle%20/%20Plus%20/%20Fill%3c/title%3e%3cdesc%3eCreated%20with%20Sketch.%3c/desc%3e%3cdefs%3e%3cpath%20d='M12,1%20C18.0751322,1%2023,5.92486775%2023,12%20C23,18.0751322%2018.0751322,23%2012,23%20C5.92486775,23%201,18.0751322%201,12%20C1,5.92486775%205.92486775,1%2012,1%20Z%20M13,5.98339844%20L11,5.98339844%20L11,10.9993984%20L6,11%20L6,13%20L11,12.9993984%20L11,17.9984266%20L13,17.9984266%20L13,12.9993984%20L18,13%20L18,11%20L13,10.9993984%20L13,5.98339844%20Z'%20id='path-1'%3e%3c/path%3e%3c/defs%3e%3cg%20id='Graphic-/-Icon-/-Toggle-/-Plus-/-Fill'%20stroke='none'%20stroke-width='1'%20fill='none'%20fill-rule='evenodd'%3e%3cmask%20id='mask-2'%20fill='white'%3e%3cuse%20xlink:href='%23path-1'%3e%3c/use%3e%3c/mask%3e%3cuse%20id='Icon'%20fill='%2300957a'%20fill-rule='nonzero'%20xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/svg%3e";
const plusOutline = "data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20width='24px'%20height='24px'%20viewBox='0%200%2024%2024'%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!--%20Generator:%20Sketch%2060.1%20(88133)%20-%20https://sketch.com%20--%3e%3ctitle%3eGraphic%20/%20Icon%20/%20Toggle%20/%20Plus%20/%20Outline%3c/title%3e%3cdesc%3eCreated%20with%20Sketch.%3c/desc%3e%3cg%20id='Graphic-/-Icon-/-Toggle-/-Plus-/-Outline'%20stroke='none'%20stroke-width='1'%20fill='none'%20fill-rule='evenodd'%3e%3cpath%20d='M12,1%20C18.0751322,1%2023,5.92486775%2023,12%20C23,18.0751322%2018.0751322,23%2012,23%20C5.92486775,23%201,18.0751322%201,12%20C1,5.92486775%205.92486775,1%2012,1%20Z%20M12.0012479,2.44889415%20C6.72700705,2.44889415%202.45139003,6.72451118%202.45139003,11.9987521%20C2.45139003,17.272993%206.72700705,21.54861%2012.0012479,21.54861%20C17.2754888,21.54861%2021.5511058,17.272993%2021.5511058,11.9987521%20C21.5511058,6.72451118%2017.2754888,2.44889415%2012.0012479,2.44889415%20Z'%20id='Outer'%20fill='%23A0A0A0'%20fill-rule='nonzero'%3e%3c/path%3e%3cpath%20d='M13,6.06068735%20L12.999,10.9996873%20L18,11%20L18,13%20L12.999,12.9996873%20L13,18.0064732%20L11,18.0064732%20L10.999,12.9996873%20L6,13%20L6,11%20L10.999,10.9996873%20L11,6.06068735%20L13,6.06068735%20Z'%20id='Inner'%20fill='%2369696E'%20fill-rule='nonzero'%3e%3c/path%3e%3c/g%3e%3c/svg%3e";
const collapsedIcon = "data:image/svg+xml,%3csvg%20width='5'%20height='5'%20viewBox='0%200%205%2010'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M4.54%204.793l-4-3.85C.378.789.125.915.125%201.15v7.7c0%20.236.253.362.415.206l4-3.85a.293.293%200%20000-.413z'%20fill='%23A0A0A0'%20fill-rule='nonzero'/%3e%3c/svg%3e";
const expandedIcon = "data:image/svg+xml,%3csvg%20width='5'%20height='5'%20viewBox='0%200%2010%205'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M5.207%204.54l3.85-4c.155-.162.029-.415-.207-.415h-7.7C.914.125.788.378.944.54l3.85%204c.109.113.303.113.413%200z'%20fill='%23A0A0A0'%20fill-rule='nonzero'/%3e%3c/svg%3e";
const LEFT_PADDING = 15;
const _Option = class _Option extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "optionLabelRef", React.createRef());
    __publicField(this, "state", {
      expanded: !!this.props.expandOptions,
      parentHeight: 0,
      showTooltip: false
    });
    __publicField(this, "toggleExpanded", () => this.setState({ expanded: !this.state.expanded }));
    __publicField(this, "onClickExpand", (e) => {
      e.stopPropagation();
      if (e.type === "click") {
        e.preventDefault();
        return this.toggleExpanded();
      }
      if (e.type === "keydown" && SUBMIT_KEYS.has(e.key)) {
        e.preventDefault();
        return this.toggleExpanded();
      }
    });
    __publicField(this, "isLabelOverflowing", () => {
      const { current: currentOptionLabelRef } = this.optionLabelRef;
      if (!currentOptionLabelRef)
        return false;
      const { offsetWidth, scrollWidth } = currentOptionLabelRef;
      return scrollWidth > offsetWidth;
    });
    __publicField(this, "componentDidMount", () => {
      this.setState({ showTooltip: this.isLabelOverflowing() });
    });
    __publicField(this, "componentDidUpdate", () => {
      this.setState({ showTooltip: this.isLabelOverflowing() });
    });
    __publicField(this, "onClickParent", () => {
      if (!this.props.selectParents) {
        return this.toggleExpanded();
      }
      const opt = { ...this.props.option };
      delete opt.children;
      this.props.onOptionClick(opt);
    });
    __publicField(this, "getIndent", (depth, onset = 0, padding = 8) => ({
      paddingLeft: depth * LEFT_PADDING + padding + onset + "px"
    }));
    __publicField(this, "optionSelected", (option) => {
      return Array.isArray(this.props.value) ? !!this.props.value.find((o) => _Option.isSame(o, option)) : false;
    });
    __publicField(this, "onParentSelect", (e) => {
      if (!e.key || e.type === "click" || e.type === "keydown" && SUBMIT_KEYS.has(e.key)) {
        e.stopPropagation();
        e.preventDefault();
        return this.onClickParent();
      }
    });
    __publicField(this, "selectOption", (e, actions, option) => {
      if (e.type === "keydown" && SUBMIT_KEYS.has(e.key)) {
        e.preventDefault();
        actions.onOptionClick(option);
      }
    });
    __publicField(this, "renderOptionLabel", () => {
      const { option, renderOptionLabel } = this.props;
      if (renderOptionLabel)
        return renderOptionLabel(option);
      return /* @__PURE__ */ jsx("span", { ref: this.optionLabelRef, className: "sa-dropdown-options-option-label", children: option.label });
    });
    __publicField(this, "wrapWithTooltip", (text, children) => {
      return /* @__PURE__ */ jsx(Tooltip, { text, customWidth: true, className: "autoWidth", children });
    });
  }
  render() {
    var _a, _b;
    const { option, expanded, depth, onOptionRemove, onOptionAdd, ...actions } = {
      ...this.props,
      ...this.state
    };
    if (this.optionSelected(option))
      return null;
    const toggleExpandLabel = `${expanded ? "Collapse" : "Expand"} category "${option.label}"`;
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      ((_a = option.children) == null ? void 0 : _a.length) ? (
        // if parent
        /* @__PURE__ */ jsx(
          ConditionalWrapper,
          {
            condition: this.state.showTooltip,
            wrapper: (children) => this.wrapWithTooltip(option.label, children),
            children: /* @__PURE__ */ jsxs(
              "div",
              {
                style: this.getIndent(depth - 1),
                className: classNames({
                  "sa-dropdown-options-option": true,
                  expanded
                }),
                children: [
                  /* @__PURE__ */ jsx(
                    Button,
                    {
                      type: "button",
                      className: "icon-button",
                      onKeyDown: this.onClickExpand,
                      onClick: this.onClickExpand,
                      title: toggleExpandLabel,
                      "aria-label": toggleExpandLabel,
                      children: /* @__PURE__ */ jsx("img", { src: expanded ? expandedIcon : collapsedIcon, alt: "Expand" })
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    "div",
                    {
                      role: "option",
                      "aria-selected": this.optionSelected(option),
                      tabIndex: this.props.selectParents ? 0 : -1,
                      className: "option-button alt-font",
                      onKeyDown: this.onParentSelect,
                      onClick: this.onParentSelect,
                      "data-testid": `option-depth-${depth}`,
                      children: this.renderOptionLabel()
                    }
                  )
                ]
              }
            )
          }
        )
      ) : this.optionSelected(option) ? null : (
        // if child
        /* @__PURE__ */ jsx(
          ConditionalWrapper,
          {
            condition: this.state.showTooltip,
            wrapper: (children) => this.wrapWithTooltip(option.label, children),
            children: /* @__PURE__ */ jsx(
              "div",
              {
                style: this.getIndent(depth - 1),
                className: classNames({
                  "sa-dropdown-options-option": true
                }),
                children: /* @__PURE__ */ jsxs(
                  "div",
                  {
                    role: "option",
                    "aria-selected": this.optionSelected(option),
                    tabIndex: 0,
                    className: "option-button alt-font",
                    onKeyDown: (e) => this.selectOption(e, actions, option),
                    onClick: () => actions.onOptionClick(option),
                    "data-testid": `option-depth-${depth}`,
                    children: [
                      this.renderOptionLabel(),
                      onOptionRemove && /* @__PURE__ */ jsx(
                        Button,
                        {
                          type: "button",
                          className: "sa-dropdown-options-option remove",
                          onClick: (e) => {
                            e.stopPropagation();
                            return onOptionRemove(option, e);
                          },
                          children: /* @__PURE__ */ jsx(
                            "img",
                            {
                              src: plusOutline,
                              className: "sa-dropdown-options-option remove-icon",
                              title: "Remove",
                              alt: "Remove"
                            }
                          )
                        }
                      ),
                      onOptionAdd && /* @__PURE__ */ jsx(
                        Button,
                        {
                          type: "button",
                          className: "sa-dropdown-options-option add",
                          onClick: (e) => {
                            e.stopPropagation();
                            return onOptionAdd(option, e);
                          },
                          children: /* @__PURE__ */ jsx(
                            "img",
                            {
                              src: plusGreen,
                              className: "sa-dropdown-options-option add-icon",
                              title: "Add",
                              alt: "Add"
                            }
                          )
                        }
                      )
                    ]
                  }
                )
              }
            )
          }
        )
      ),
      expanded && /* @__PURE__ */ jsx(Fragment, { children: (_b = option.children) == null ? void 0 : _b.map((option2, i) => /* @__PURE__ */ createElement(_Option, { ...this.props, depth: depth + 1, option: option2, key: i })) })
    ] });
  }
};
__publicField(_Option, "defaultProps", {
  depth: 1
});
__publicField(_Option, "isSame", (op1, op2) => {
  if (op1.key)
    return op1.key === op2.key;
  return op1.label === op2.label;
});
let Option = _Option;
const MAX_HEIGHT = 200;
class Dropdown extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      open: false,
      filterString: "",
      error: "",
      touched: false,
      focus: 0
    });
    __publicField(this, "dropdown", null);
    __publicField(this, "options", null);
    __publicField(this, "input", null);
    __publicField(this, "initialHeight", 0);
    __publicField(this, "dropdownIcon", null);
    __publicField(this, "tags", null);
    __publicField(this, "getRef", () => {
      return this.dropdown;
    });
    __publicField(this, "onOptionClick", (clickedOption) => {
      var _a, _b;
      this.setState({
        open: false
      });
      (_b = (_a = this.props).onClose) == null ? void 0 : _b.call(_a);
      this.props.onChange(clickedOption);
    });
    __publicField(this, "checkOutsideClick", (e) => {
      var _a, _b, _c, _d, _e, _f, _g;
      if (this.state.open && !((_a = this.options) == null ? void 0 : _a.contains(e.target))) {
        if (((_d = (_c = (_b = this.input) == null ? void 0 : _b.input) == null ? void 0 : _c.current) == null ? void 0 : _d.contains(e.target)) || ((_e = this.dropdownIcon) == null ? void 0 : _e.contains(e.target)))
          return;
        this.setState(
          {
            open: false
          },
          this.onBlur
        );
        (_g = (_f = this.props).onClose) == null ? void 0 : _g.call(_f);
      }
    });
    __publicField(this, "isValid", () => {
      const { value, required } = this.props;
      if (!required)
        return true;
      else
        this.setState({ ...this.state, error: "This field is required" });
      if (this.state.open)
        return true;
      if (Array.isArray(value)) {
        return !!value.length;
      }
      return !!value;
    });
    __publicField(this, "onBlur", () => {
      this.setState({ touched: true });
    });
    __publicField(this, "focus", () => {
      var _a;
      (_a = this.dropdown) == null ? void 0 : _a.focus();
    });
    __publicField(this, "reset", () => {
      var _a, _b;
      this.props.hierarchyClear ? null : (_b = (_a = this.props).onClear) == null ? void 0 : _b.call(_a);
    });
    __publicField(this, "onClick", (e) => {
      var _a, _b, _c, _d, _e, _f;
      e.stopPropagation();
      if (this.tags !== e.target && ((_a = this.tags) == null ? void 0 : _a.contains(e.target)))
        return;
      if (this.props.disabled || this.state.open && this.props.filter && !((_b = this.dropdownIcon) == null ? void 0 : _b.contains(e.target)))
        return;
      this.state.open ? (_d = (_c = this.props).onClose) == null ? void 0 : _d.call(_c) : (_f = (_e = this.props).onOpen) == null ? void 0 : _f.call(_e);
      this.setState(
        {
          open: !this.state.open,
          error: "",
          filterString: !this.state.open ? "" : this.state.filterString
        },
        () => {
          var _a2;
          return (_a2 = this.input) == null ? void 0 : _a2.focus();
        }
      );
    });
    __publicField(this, "onClear", () => {
      var _a, _b, _c, _d;
      this.setState({ filterString: "", open: false });
      (_b = (_a = this.props).onClose) == null ? void 0 : _b.call(_a);
      (_d = (_c = this.props).onClear) == null ? void 0 : _d.call(_c);
    });
    __publicField(this, "renderValue", () => {
      const { value, placeholder } = this.props;
      if (Array.isArray(value)) {
        return value.length ? /* @__PURE__ */ jsx("div", { ref: (r) => this.tags = r, className: "sa-dropdown-tags", children: value.map((opt, i) => /* @__PURE__ */ jsxs(Tag, { title: opt.label, square: true, small: true, type: "grey", children: [
          opt.label,
          /* @__PURE__ */ jsx(
            Button,
            {
              title: `${opt.label} clear button`,
              "aria-label": `${opt.label}. clear`,
              type: "button",
              tabIndex: this.state.open ? -1 : 0,
              className: "clear-button",
              elastic: true,
              onClick: () => {
                var _a, _b;
                return (_b = (_a = this.props).onDeleteTag) == null ? void 0 : _b.call(_a, opt, i);
              },
              onKeyDown: (evt) => {
                var _a, _b;
                if (evt.key === "Enter" || evt.key === " ") {
                  evt.preventDefault();
                  (_b = (_a = this.props).onDeleteTag) == null ? void 0 : _b.call(_a, opt, i);
                }
              },
              children: /* @__PURE__ */ jsx("img", { src: CrossIcon, alt: "Clear" })
            }
          )
        ] }, i)) }) : /* @__PURE__ */ jsx("span", { className: "sa-dropdown-placeholder", children: placeholder ?? "" });
      }
      return /* @__PURE__ */ jsx("span", { className: classNames({
        "sa-dropdown-placeholder": !value,
        "sa-selected": !!value
      }), "data-testid": "dropdown-value", children: this.state.open ? "" : value || placeholder || "" });
    });
    __publicField(this, "renderOptions", () => {
      const { options, value, maxFilterOptions, expandOptions, filterString, onAdd, onSort, minWordLength } = {
        ...this.props,
        ...this.state
      };
      const rendered = options.filter(function recursiveFilter(option) {
        var _a;
        if (option.label.toLowerCase().includes(filterString.toLowerCase().trim()))
          return true;
        if ((_a = option.children) == null ? void 0 : _a.length) {
          return !!option.children.filter(recursiveFilter).length;
        }
        return false;
      }).filter((option) => Array.isArray(value) ? !value.map((v) => v.label).includes(option.label) : option).slice(0, maxFilterOptions).sort((a, b) => onSort ? onSort(a, b) : 0).map((option, i) => /* @__PURE__ */ jsx(
        Option,
        {
          renderOptionLabel: this.props.renderOptionLabel,
          expandOptions: !!expandOptions,
          selectParents: !!this.props.selectParents,
          value,
          onOptionClick: this.onOptionClick,
          onOptionRemove: this.props.onRemove,
          option
        },
        i
      ));
      if (filterString && filterString.length >= (minWordLength || 0) && onAdd)
        rendered.unshift(
          /* @__PURE__ */ jsx(
            Option,
            {
              renderOptionLabel: this.props.renderOptionLabel,
              expandOptions: !!expandOptions,
              selectParents: !!this.props.selectParents,
              value: filterString,
              onOptionClick: (e) => {
                onAdd(e);
                this.setState({ filterString: "" });
              },
              onOptionAdd: (e) => {
                onAdd(e);
                this.setState({ filterString: "" });
              },
              option: {
                label: `Add '${filterString}'`,
                data: filterString
              }
            },
            "add"
          )
        );
      return rendered.length ? rendered : /* @__PURE__ */ jsx("div", { className: "sa-dropdown-options-option no-results", children: "No results" });
    });
    __publicField(this, "setDropdown", (r) => {
      this.dropdown = r;
    });
    __publicField(this, "onFocusChange", () => {
      var _a;
      const child = (_a = this.options) == null ? void 0 : _a.children[this.state.focus];
      const optionButton = child == null ? void 0 : child.querySelector(".option-button");
      optionButton == null ? void 0 : optionButton.focus();
    });
    __publicField(this, "handleKeypressOnClear", (evt) => {
      if (evt.key === "Enter" || evt.key === " ") {
        this.onClear();
      }
    });
    __publicField(this, "onKeypress", (evt) => {
      var _a, _b, _c, _d;
      const { focus } = this.state;
      const KEY_CODES = {
        UP: 38,
        DOWN: 40
      };
      if (evt.key === "Enter" || evt.key === " ") {
        if (this.props.preventDefaultInputOnEnterKey)
          evt.preventDefault();
        this.onClick(evt);
      }
      if (evt.key === "Escape") {
        this.setState({
          open: false,
          error: "",
          filterString: ""
        });
        (_b = (_a = this.props).onClose) == null ? void 0 : _b.call(_a);
      }
      if (evt.keyCode === KEY_CODES.UP) {
        if (focus === 0)
          return;
        evt.preventDefault();
        this.setState({ focus: focus - 1 }, this.onFocusChange);
      }
      if (evt.keyCode === KEY_CODES.DOWN) {
        if (focus === ((_d = (_c = this.options) == null ? void 0 : _c.children) == null ? void 0 : _d.length) - 1)
          return;
        evt.preventDefault();
        this.setState({ focus: focus + 1 }, this.onFocusChange);
      }
    });
  }
  componentDidMount() {
    document.addEventListener("mouseup", this.checkOutsideClick, false);
    if (this.props.autoFocus) {
      this.focus();
    }
    if (this.props.attachToForm) {
      this.props.attachToForm(this);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mouseup", this.checkOutsideClick, false);
    if (this.props.removeFromForm) {
      this.props.removeFromForm(this);
    }
  }
  getOptionsStyle() {
    var _a;
    const rect = (_a = this.dropdown) == null ? void 0 : _a.getBoundingClientRect();
    if (!this.props.forceOpenDown && this.isDropdownFromBottom()) {
      return {
        bottom: rect == null ? void 0 : rect.height,
        width: rect == null ? void 0 : rect.width,
        position: "absolute",
        left: -2
      };
    }
    return {
      top: rect == null ? void 0 : rect.height,
      left: -2,
      width: rect == null ? void 0 : rect.width,
      position: "absolute"
    };
  }
  isDropdownFromBottom() {
    const y = this.dropdown.getBoundingClientRect().y;
    const body = document.getElementsByTagName("body")[0];
    if (body.scrollHeight <= MAX_HEIGHT)
      return false;
    return body.scrollHeight - y < MAX_HEIGHT;
  }
  render() {
    const { open, filterString, error, touched } = this.state;
    const {
      value,
      label,
      filter,
      disabled,
      showError,
      className,
      curved,
      iconRight,
      title,
      hierarchyClear,
      onAdd,
      required,
      truncateSelected,
      forceOpenDown,
      ...actions
    } = this.props;
    return /* @__PURE__ */ jsxs(
      "div",
      {
        className: classNames({
          "sa-dropdown-wrapper": true,
          [className]: !!className
        }),
        children: [
          label && /* @__PURE__ */ jsxs("label", { children: [
            label,
            required ? /* @__PURE__ */ jsx("span", { "aria-hidden": "true", children: "*" }) : null
          ] }),
          required && /* @__PURE__ */ jsx("span", { className: "sa-dropdown-label-hidden", children: "required" }),
          /* @__PURE__ */ jsxs(
            "div",
            {
              "aria-label": `${title || label || ""} dropdown`,
              "aria-expanded": open,
              "aria-haspopup": "true",
              title: title || label || "Dropdown",
              tabIndex: 0,
              role: "button",
              ref: this.setDropdown,
              onBlur: this.onBlur,
              onClick: this.onClick,
              onKeyDown: this.onKeypress,
              className: classNames({
                "sa-dropdown": true,
                "contains-tags": Array.isArray(value) && value.length,
                error: touched && !this.isValid(),
                curved,
                disabled,
                open,
                "show-ellipsis": truncateSelected
              }),
              children: [
                open && filter ? /* @__PURE__ */ jsxs("div", { className: "sa-dropdown-filter", children: [
                  /* @__PURE__ */ jsx(
                    Input,
                    {
                      ref: (r) => this.input = r,
                      placeholder: this.props.placeholder,
                      value: filterString,
                      preventDefaultOnEnterKey: this.props.preventDefaultInputOnEnterKey,
                      onChange: (filterString2) => this.setState({ filterString: filterString2 }),
                      onSubmit: (filterString2) => {
                        if (onAdd) {
                          onAdd({ label: filterString2, data: filterString2 });
                          this.setState({ filterString: "" });
                        }
                      }
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    "img",
                    {
                      className: "sa-dropdown-filter-icon",
                      src: SearchIcon,
                      height: 16,
                      width: 16,
                      alt: "Search"
                    }
                  )
                ] }) : null,
                this.renderValue(),
                /* @__PURE__ */ jsxs("div", { className: "sa-dropdown-icons", children: [
                  actions.onClear && (value == null ? void 0 : value.length) ? /* @__PURE__ */ jsx(
                    Button,
                    {
                      type: "button",
                      className: "full-width",
                      onKeyDown: this.handleKeypressOnClear,
                      onClick: this.onClear,
                      children: /* @__PURE__ */ jsx("img", { className: "sa-dropdown-icons-cross", src: CrossIcon, alt: "Clear", title: "Clear" })
                    }
                  ) : null,
                  /* @__PURE__ */ jsx(
                    Button,
                    {
                      "aria-hidden": "true",
                      type: "button",
                      tabIndex: iconRight ? 0 : -1,
                      onKeyDown: this.onKeypress,
                      onClick: this.onClick,
                      children: iconRight ?? /* @__PURE__ */ jsx(
                        "img",
                        {
                          src: DropdownIcon,
                          ref: (r) => this.dropdownIcon = r,
                          alt: "Dropdown icon",
                          title: "Dropdown icon"
                        }
                      )
                    }
                  )
                ] }),
                open && /* @__PURE__ */ jsx(
                  "div",
                  {
                    role: "listbox",
                    ref: (r) => this.options = r,
                    style: this.getOptionsStyle(),
                    className: classNames({
                      "sa-dropdown-options": true,
                      "from-bottom": forceOpenDown ? false : this.isDropdownFromBottom()
                    }),
                    children: this.renderOptions()
                  }
                )
              ]
            }
          ),
          showError && touched && !this.isValid() && /* @__PURE__ */ jsx("div", { className: "input-error", children: error })
        ]
      }
    );
  }
}
const forwardRef = React.forwardRef(function forwardRef2(props, ref) {
  return /* @__PURE__ */ jsx(FormConsumer, { children: (context) => /* @__PURE__ */ jsx(
    Dropdown,
    {
      ...props,
      ref,
      attachToForm: context == null ? void 0 : context.attachToForm,
      removeFromForm: context == null ? void 0 : context.removeFromForm
    }
  ) });
});
const infoButton = "data:image/svg+xml,%3csvg%20width='14'%20height='14'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath%20d='M7%20.583a6.417%206.417%200%20110%2012.834A6.417%206.417%200%20017%20.583zm0%20.755a5.662%205.662%200%20100%2011.324A5.662%205.662%200%20007%201.338zm.802%204.86v4.01H6.198v-4.01h1.604zM7%203.792a.802.802%200%20110%201.604.802.802%200%20010-1.604z'%20id='a'/%3e%3c/defs%3e%3cuse%20fill='%23B9C1CB'%20fill-rule='nonzero'%20xlink:href='%23a'/%3e%3c/svg%3e";
var SAFormBuilderFieldType = /* @__PURE__ */ ((SAFormBuilderFieldType2) => {
  SAFormBuilderFieldType2["Radio"] = "radio";
  SAFormBuilderFieldType2["Checkbox"] = "checkbox";
  SAFormBuilderFieldType2["Text"] = "text";
  SAFormBuilderFieldType2["DropDown"] = "dropdown";
  SAFormBuilderFieldType2["Hierarchy"] = "hierarchy";
  SAFormBuilderFieldType2["Datepicker"] = "datepicker";
  SAFormBuilderFieldType2["Timepicker"] = "timepicker";
  return SAFormBuilderFieldType2;
})(SAFormBuilderFieldType || {});
const withHandleError = (Component2) => {
  return class withHandleError extends React.PureComponent {
    constructor() {
      super(...arguments);
      __publicField(this, "state", {
        error: void 0
      });
    }
    componentDidCatch(err) {
      console.error(err);
      this.setState({ error: "Unable to display contact log form" });
    }
    render() {
      if (this.state.error)
        return /* @__PURE__ */ jsx("p", { children: this.state.error });
      return /* @__PURE__ */ jsx(Component2, { ...this.props });
    }
  };
};
const __FormBuilder = class __FormBuilder extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "shouldDisplay", (condition) => {
      const { values } = this.state;
      if (!condition)
        return true;
      const field = values[condition.field];
      const value = condition.value || values[condition.compareField];
      switch (condition.comparator) {
        case "!exists":
          return Array.isArray(field) ? !field.length : !field;
        case "exists":
          return Array.isArray(field) ? !!field.length : !!field;
        case "=":
          return Array.isArray(field) ? !!field.find((f) => (f.data || f) === value) : field === value;
        case "!=":
          return Array.isArray(field) ? field.find((f) => (f.data || f) === value) : field !== value;
        case "<":
          return Array.isArray(field) ? field.find((f) => Number(f.data || f) < Number(value)) : Number(field) < Number(value);
        case ">":
          return Array.isArray(field) ? field.find((f) => Number(f.data || f) > Number(value)) : Number(field) > Number(value);
        case "begins-with":
          return Array.isArray(field) ? field.find((f) => (f.data || f).indexOf(value) === 0) : field.indexOf(value) === 0;
        default:
          return true;
      }
    });
    __publicField(this, "Common", ({
      children,
      comments,
      name,
      text,
      info,
      condition
    }) => {
      const currentComments = this.state.comments;
      const { onChange } = this.props;
      if (!this.shouldDisplay(condition))
        return null;
      return /* @__PURE__ */ jsxs("div", { className: "sa-form-field", children: [
        text ? /* @__PURE__ */ jsxs("div", { className: "sa-form-field-title", children: [
          /* @__PURE__ */ jsx("span", { children: text }),
          info && /* @__PURE__ */ jsx(
            TriggeredPopup,
            {
              closeOnClickOutside: true,
              fromRight: true,
              trigger: /* @__PURE__ */ jsx(
                "img",
                {
                  className: "sa-form-field-info",
                  alt: "Info",
                  src: infoButton
                }
              ),
              children: info
            }
          )
        ] }) : null,
        children,
        comments && /* @__PURE__ */ jsx(
          Input,
          {
            onBlur: () => onChange == null ? void 0 : onChange(`${name}-comments`, this.state.comments[name]),
            textarea: true,
            rows: 3,
            name: "Comments text area",
            placeholder: "Comments",
            value: currentComments[name] || "",
            className: "sa-form-field-comments",
            onChange: (value) => this.setState({
              comments: {
                ...currentComments,
                [name]: value
              }
            })
          }
        )
      ] });
    });
    __publicField(this, "onSubmit", (event) => {
      const { data, values, comments } = this.state;
      const blob = Object.keys(values).map((name) => {
        const field = this.props.data.fields.find((field2) => field2.name === name);
        let value = values[name];
        if (value && (field.type === "dropdown" || field.type === "hierarchy")) {
          value = Array.isArray(values[name]) ? values[name] : data[name];
          value = this.getDropdownValue(value);
        }
        return {
          name,
          value,
          comments: comments[name]
        };
      });
      if (this.props.preventDefault && event) {
        event.preventDefault();
      }
      this.props.onSubmit && this.props.onSubmit(blob);
    });
    __publicField(this, "onCancel", () => {
      this.props.onCancel && this.props.onCancel();
    });
    __publicField(this, "getDropdownValue", (values) => {
      if (Array.isArray(values)) {
        return values.map((v) => v.data);
      }
      return values;
    });
    __publicField(this, "onSubmitError", (invalid) => {
      requestAnimationFrame(() => {
        var _a, _b;
        (_b = (_a = invalid[0].getRef()) == null ? void 0 : _a.closest(".sa-form-field")) == null ? void 0 : _b.scrollIntoView();
      });
    });
    this.state = {
      values: __FormBuilder.getInitialValuesFromProps(props),
      data: {},
      comments: {}
    };
  }
  static convertToArray(val) {
    return Array.isArray(val) ? val : [val];
  }
  render() {
    const { data, renderSubmit, onChange } = this.props;
    const title = data.title || this.props.title;
    const { values } = this.state;
    return /* @__PURE__ */ jsxs(Form, { onSubmit: this.onSubmit, onSubmitError: this.onSubmitError, children: [
      /* @__PURE__ */ jsxs("div", { className: "sa-form-content", children: [
        title && /* @__PURE__ */ jsx("h1", { children: title }),
        data.fields.map((field, i) => {
          const { name } = field;
          return (() => {
            switch (field.type) {
              case "text":
                return /* @__PURE__ */ jsx(this.Common, { ...field, children: /* @__PURE__ */ jsx(
                  Input,
                  {
                    onBlur: () => onChange == null ? void 0 : onChange(name, this.state.values[name]),
                    type: "text",
                    name: field.name,
                    label: field.label,
                    textarea: field.multiline,
                    rows: field.rows,
                    required: field.required,
                    placeholder: field.placeholder,
                    value: values[name],
                    onChange: (value) => this.setState({
                      values: {
                        ...values,
                        [name]: value
                      }
                    })
                  }
                ) }, i);
              case "checkbox": {
                const selectedValues = values[name];
                return /* @__PURE__ */ jsx(this.Common, { ...field, children: field.options.map((value, i2) => {
                  return /* @__PURE__ */ jsx(
                    Checkbox,
                    {
                      square: true,
                      label: value,
                      checked: selectedValues.includes(value),
                      onChange: (checked) => this.setState(
                        {
                          values: {
                            ...values,
                            [name]: checked ? [
                              ...selectedValues,
                              value
                            ] : selectedValues.filter(
                              (v) => v !== value
                            )
                          }
                        },
                        () => onChange == null ? void 0 : onChange(
                          name,
                          this.state.values[name]
                        )
                      )
                    },
                    `${i2}-${value}`
                  );
                }) });
              }
              case "radio":
                return /* @__PURE__ */ jsx(this.Common, { ...field, children: field.options.map((value, i2) => {
                  return /* @__PURE__ */ jsx(
                    Checkbox,
                    {
                      label: value,
                      checked: values[name] === value,
                      onChange: (checked) => checked && this.setState(
                        {
                          values: {
                            ...values,
                            [name]: value
                          }
                        },
                        () => onChange == null ? void 0 : onChange(
                          name,
                          this.state.values[name]
                        )
                      )
                    },
                    `${i2}-${value}`
                  );
                }) });
              case "dropdown": {
                const selectedValues = values[name];
                return /* @__PURE__ */ jsx(this.Common, { ...field, children: /* @__PURE__ */ jsx(
                  forwardRef,
                  {
                    filter: field.filter,
                    options: field.options,
                    value: values[name],
                    placeholder: field.placeholder,
                    label: field.label,
                    maxFilterOptions: field.maxFilterOptions,
                    required: field.required,
                    preventDefaultInputOnEnterKey: this.props.preventDefault,
                    onChange: (option) => {
                      const isArray = Array.isArray(selectedValues);
                      this.setState(
                        {
                          values: {
                            ...values,
                            [name]: isArray ? [...selectedValues, option] : option.label
                          },
                          data: {
                            ...this.state.data,
                            [name]: isArray ? [...selectedValues, option] : option.data
                          }
                        },
                        () => onChange == null ? void 0 : onChange(
                          name,
                          this.getDropdownValue(
                            this.state.data[name]
                          )
                        )
                      );
                    },
                    onDeleteTag: (option) => {
                      this.setState(
                        {
                          values: {
                            ...values,
                            [name]: values[name].filter(
                              (v) => v.label !== option.label
                            )
                          },
                          data: {
                            ...this.state.data,
                            [name]: this.state.data[name].filter(
                              (v) => v.data !== option.data
                            )
                          }
                        },
                        () => onChange == null ? void 0 : onChange(
                          name,
                          this.getDropdownValue(
                            this.state.data[name]
                          )
                        )
                      );
                    }
                  }
                ) });
              }
              case "hierarchy": {
                const selectedValues = values[name];
                return /* @__PURE__ */ jsx(this.Common, { ...field, children: /* @__PURE__ */ jsx(
                  forwardRef,
                  {
                    filter: field.filter,
                    options: field.options,
                    value: values[name],
                    placeholder: field.placeholder,
                    label: field.label,
                    maxFilterOptions: field.maxFilterOptions,
                    required: field.required,
                    preventDefaultInputOnEnterKey: this.props.preventDefault,
                    onChange: (option) => {
                      const isArray = Array.isArray(selectedValues);
                      this.setState(
                        {
                          values: {
                            ...values,
                            [name]: isArray ? [...selectedValues, option] : option.label
                          },
                          data: {
                            ...this.state.data,
                            [name]: isArray ? [...selectedValues, option] : option.data
                          }
                        },
                        () => onChange == null ? void 0 : onChange(
                          name,
                          this.getDropdownValue(
                            this.state.data[name]
                          )
                        )
                      );
                    },
                    onDeleteTag: (option) => {
                      this.setState(
                        {
                          values: {
                            ...values,
                            [name]: values[name].filter(
                              (v) => v.label !== option.label
                            )
                          },
                          data: {
                            ...this.state.data,
                            [name]: this.state.data[name].filter(
                              (v) => v.data !== option.data
                            )
                          }
                        },
                        () => onChange == null ? void 0 : onChange(
                          name,
                          this.getDropdownValue(
                            this.state.data[name]
                          )
                        )
                      );
                    }
                  }
                ) });
              }
              case "datepicker": {
                return /* @__PURE__ */ jsx(this.Common, { ...field, children: /* @__PURE__ */ jsx(
                  ReactDatePicker,
                  {
                    onBlur: () => onChange == null ? void 0 : onChange(name, this.state.values[name]),
                    name: field.name,
                    required: field.required,
                    value: values[name],
                    onChange: (value) => {
                      this.setState(
                        {
                          values: {
                            ...values,
                            [name]: value.toString()
                          }
                        },
                        () => onChange == null ? void 0 : onChange(
                          name,
                          this.state.values[name]
                        )
                      );
                    },
                    label: field.label
                  }
                ) }, i);
              }
              case "timepicker": {
                return /* @__PURE__ */ jsx(this.Common, { ...field, children: /* @__PURE__ */ jsx(
                  Input,
                  {
                    onBlur: () => onChange == null ? void 0 : onChange(name, this.state.values[name]),
                    name: field.name,
                    required: field.required,
                    value: values[name],
                    onChange: (value) => this.setState({
                      values: {
                        ...values,
                        [name]: value
                      }
                    }),
                    type: "time",
                    label: field.label
                  }
                ) }, i);
              }
              default:
                return null;
            }
          })();
        })
      ] }),
      renderSubmit ? renderSubmit() : /* @__PURE__ */ jsxs("div", { className: "sa-form-controls", children: [
        /* @__PURE__ */ jsx(Button, { round: true, styling: "secondary", onClick: this.onCancel, children: "Cancel" }),
        /* @__PURE__ */ jsx(Button, { round: true, styling: "primary", onClick: this.onSubmit, children: "Submit" })
      ] })
    ] });
  }
};
__publicField(__FormBuilder, "getInitialValuesFromProps", (props) => {
  const { fields } = props.data;
  const { defaultValues } = props;
  const names = fields.map((field) => field.name);
  if ([...new Set(names)].length < names.length)
    throw new Error("No duplicate names allowed");
  return fields.reduce((prevFields, field) => {
    const defaultValue = defaultValues == null ? void 0 : defaultValues[field.name];
    switch (field.type) {
      case "text":
      case "radio":
      case "datepicker":
      case "timepicker":
        return {
          ...prevFields,
          [field.name]: defaultValue ?? ""
        };
      case "checkbox":
        return {
          ...prevFields,
          [field.name]: defaultValue ? __FormBuilder.convertToArray(defaultValue) : []
        };
      case "dropdown":
        return {
          ...prevFields,
          [field.name]: field.multi ? defaultValue ? __FormBuilder.convertToArray(defaultValue) : [] : defaultValue ?? ""
        };
      case "hierarchy":
        return {
          ...prevFields,
          [field.name]: field.multi ? defaultValue ? __FormBuilder.convertToArray(defaultValue) : [] : defaultValue ?? ""
        };
      default:
        throw new Error("Unknown field type");
    }
  }, {});
});
let _FormBuilder = __FormBuilder;
const FormBuilder = withHandleError(_FormBuilder);
export {
  Dropdown as D,
  FormBuilder as F,
  Option as O,
  SAFormBuilderFieldType as S,
  _FormBuilder as _,
  forwardRef as f
};
