import { sendEvent } from '@missionlabs/analytics-core'
import useAgentsAssistance from 'hooks/redux/useAgentsAssistance'
import useUser from 'hooks/redux/useUser'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import useInterval from 'hooks/useInterval'
import HandsUpIconAcknowledged from 'images/icon-agent-hands-up-acknowledged.svg'
import HandsUpIconRaised from 'images/icon-agent-hands-up-raised.svg'
import HandsUpIcon from 'images/icon-agent-hands-up.svg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAgentsAssistByAgent,
    updateAgentsAssist,
} from 'store/agentsAssistance/agentsAssistance.actions'
import AgentsAssistanceState from 'store/agentsAssistance/agentsAssistance.state'
import RootState from 'store/state'
import { v4 as uuidv4 } from 'uuid'
import './index.scss'
import { getEnvironment } from 'utils'

const HandsUpWrapper = () => {
    const hasFeature = useHasFeature()
    return hasFeature(AppFeatures.AGENT_ASSISTANCE) ? <HandsUp /> : null
}

const HandsUp: React.FC = () => {
    const dispatch = useDispatch()
    const { agentID, name } = useUser() ?? {}
    const companyID = useSelector((state: RootState) => state.app.ID)
    const roles = useSelector((state: RootState) => state.auth.roles)
    const currentAssistanceState: AgentsAssistanceState = useAgentsAssistance()
    const [isHandRaised, setIsHandRaised] = useState<boolean>(currentAssistanceState.raisedAt !== 0)
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(
        currentAssistanceState.acknowledgement.acknowledgedAt !== 0,
    )
    const helpText: string = isHandRaised
        ? 'Lower your hand'
        : 'Raise your hand for supervisor assistance'

    useInterval(() => {
        if (!isHandRaised) return
        dispatch(getAgentsAssistByAgent())
    }, 5000)

    useEffect(() => {
        dispatch(getAgentsAssistByAgent())
    }, [])

    useEffect(() => {
        setIsAcknowledged(currentAssistanceState.acknowledgement.acknowledgedAt !== 0)
        setIsHandRaised(currentAssistanceState.raisedAt !== 0)
    }, [currentAssistanceState])

    const getHandsUpImgSrc = () => {
        if (isAcknowledged) return HandsUpIconAcknowledged
        if (isHandRaised) return HandsUpIconRaised
        return HandsUpIcon
    }

    const changeHandRaisedStatus = () => {
        const newIsHandRaised: boolean = !isHandRaised
        setIsHandRaised(newIsHandRaised)
        const newState: AgentsAssistanceState = {
            ...currentAssistanceState,
            ID: newIsHandRaised ? uuidv4() : currentAssistanceState.ID,
            agentID: agentID ?? '',
            raisedAt: newIsHandRaised ? Date.now() : currentAssistanceState.raisedAt,
            resolvedAt: newIsHandRaised ? 0 : Date.now(),
        }
        dispatch(updateAgentsAssist(newState, newIsHandRaised))
        sendEvent(newIsHandRaised ? 'start' : 'end', {
            environment: getEnvironment(),
            companyName: companyID,
            userName: name,
            feature: `Agent support ${newIsHandRaised ? 'required' : 'resolved'}`,
            label: `Agent support ${newIsHandRaised ? 'required' : 'resolved'}`,
            roles: roles?.join(','),
            buttonName: `Agent hands up: Support ${newIsHandRaised ? 'required' : 'resolved'}`,
        })
    }

    return (
        <div className="sa-agent-hands-up" data-testid="sa-agent-hands-up">
            <button
                className="button button-blank sa-agent-hands-up-button"
                onClick={changeHandRaisedStatus}
                title={helpText}
            >
                <img src={getHandsUpImgSrc()} alt="Hand icon" />
            </button>
        </div>
    )
}

export default HandsUpWrapper
