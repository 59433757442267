import { Button, Popup, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import AgentsDirectory from 'components/AgentDirectory'
import { LOG_GROUP_ATTRIBUTE_NAME } from 'constants/index'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import React, { useEffect, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { FormState } from 'store/app/app.state'
import RootState from 'store/state'
import {
    logout,
    setAfterCallStatus,
    setAvailable,
    setShowNextStatus,
    setState,
} from 'store/user/user.actions'
import {
    getFormName,
    getRequiredFields,
    isAllRequiredFieldsFilled as isAllRequiredFieldsFilledFunc,
    isContactLogEmpty,
} from 'utils'
import HandsUp from 'views/HandsUp'
import Announcements from 'views/UserAnnouncements'
import UserStatus from './UserStatus'
import './user.scss'
import UserStatusOptions from './userStatusOptions'

export interface UserProps {
    blockStatusChange?: boolean
}

const UserDetails: React.FC<UserProps> = ({ blockStatusChange }) => {
    const dispatch = useDispatch()
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [status, setStatus] = useState<connect.AgentStateDefinition | undefined>(undefined)
    const popupRef = useRef<TriggeredPopup | null>(null)
    const prevNextStatusRef = useRef<string | undefined>()
    const prevCurrentStatusNameRef = useRef<string | undefined>()

    // Use useSelector to get state from Redux
    const {
        hasUser,
        currentStatusName,
        name,
        states,
        nextStatus,
        beforeStatus,
        showNextStatus,
        ccpURL,
        hideOfflineStatus,
        contact,
        acwConnections,
        contacts,
        forms,
        afterCall,
        app,
        changingState,
    } = useSelector((state: RootState) => {
        const { user, app, global, chat, tasks, contacts, contact, auth } = state
        const { status, name, states, afterCallStatus, showNextStatus, beforeCallStatus } =
            user ?? {}
        const acwChats = chat.connections.filter((c) => c.acw)
        const acwTasks = tasks.connections.filter((c) => c.acw)
        return {
            hasUser: !!status,
            currentStatusName: status?.name,
            currentStatus: status,
            name: name,
            afterCall: status?.name === connect.AgentAvailStates.AFTER_CALL_WORK,
            states: states,
            nextStatus: afterCallStatus?.name,
            beforeStatus: beforeCallStatus?.name,
            showNextStatus: showNextStatus,
            features: app.features,
            ccpURL: app.instance?.ccpURL ?? '',
            hideOfflineStatus: app.appConfig.hideOfflineStatus,
            changingState: global.changingState,
            acwConnections: [...acwChats, ...acwTasks],
            contacts: contacts,
            forms: app.forms,
            contact: contact,
            isAgent: auth.roles?.includes('AGENT'),
            app,
        }
    })
    const hasFeature = useHasFeature()
    const agentDirectory = hasFeature(AppFeatures.AGENTS_DIRECTORY)

    useEffect(() => {
        const isNextStatusNew = nextStatus !== prevNextStatusRef.current
        const isUserStatusNameNew = currentStatusName !== prevCurrentStatusNameRef.current
        const shouldClosePopup = isNextStatusNew || isUserStatusNameNew

        if (shouldClosePopup && popupRef.current) {
            popupRef.current.closePopup()
        }

        prevNextStatusRef.current = nextStatus
        prevCurrentStatusNameRef.current = currentStatusName
    }, [nextStatus, currentStatusName])

    const setUserState = (newStatus: connect.AgentStateDefinition, shouldOpenPopup: boolean) => {
        const { CALLING_CUSTOMER, BUSY } = connect.AgentAvailStates
        const statusesForCall = [CALLING_CUSTOMER, BUSY]

        const isUserNextStatusEqual = nextStatus === newStatus.name && showNextStatus
        if (isUserNextStatusEqual) return

        const isOnCall =
            statusesForCall.includes(currentStatusName as connect.AgentAvailStates) &&
            newStatus.name === beforeStatus
        //If on call then our current status will be "busy" so use beforeStatus as the current status
        const currentStatus = isOnCall ? beforeStatus : currentStatusName

        const isUserStatusEqual = currentStatus === newStatus.name

        const { requiredFieldsFilled, acwConnectionsIDs, requiredFields } =
            getContactLogRequestedFields()
        //contactLog form exist

        const isAllContactLogsFilled = requiredFieldsFilled.every((el) => el)
        //without contactLog form
        const isMoreThanOneContactInACW =
            acwConnectionsIDs.length > 1 && !requiredFieldsFilled.length
        const showWarningMessage =
            requiredFields.length > 0 && (isAllContactLogsFilled || isMoreThanOneContactInACW)

        if (showWarningMessage && shouldOpenPopup) {
            setShowConfirmation(true)
            return
        }
        if (isUserStatusEqual && !showNextStatus) return
        if (isUserStatusEqual && showNextStatus) {
            batch(() => {
                dispatch(setAfterCallStatus(newStatus))
                dispatch(setShowNextStatus(false))
            })
        }
        dispatch(setState(newStatus))
    }

    const getContactLogRequestedFields = () => {
        interface FormStateWithContactID extends FormState {
            id: string
        }
        const allContactLogs: FormStateWithContactID[] = []

        contacts.forEach((contact) => {
            const logGroup = contact?.attributes?.[LOG_GROUP_ATTRIBUTE_NAME]
            const formName = getFormName(logGroup, app)
            const formObject = forms[formName]
            if (!formObject?.form) return
            allContactLogs.push({ ...formObject, id: contact.ID })
        })

        const requiredFieldsFilled: boolean[] = []
        const acwConnectionsIDs = acwConnections.map((c) => c.id)
        if (afterCall) {
            acwConnectionsIDs.push(
                contacts.find((c) => c.channel === connect.ChannelType.VOICE)?.ID!,
            )
        }

        let requiredFields: string[] = []
        const acwContacts = contacts.filter((c) => acwConnectionsIDs.includes(c.ID))
        acwContacts.forEach((contact) => {
            const contactLogForm = allContactLogs.find((contactLog) => contactLog.id === contact.ID)
            if (!contactLogForm) return
            const noContactLogOrEmpty = isContactLogEmpty(contactLogForm)
            if (noContactLogOrEmpty) return
            const { acwAttributes } = contact
            if (acwAttributes) {
                requiredFields = getRequiredFields(contactLogForm, acwAttributes)
                requiredFieldsFilled.push(
                    isAllRequiredFieldsFilledFunc(acwAttributes, requiredFields),
                )
            }
        })
        return { requiredFieldsFilled, acwConnectionsIDs, requiredFields }
    }

    const onConfirm = () => {
        if (status) {
            setUserState(status, false)
        }
        setShowConfirmation(false)
    }

    const onCancel = () => {
        setShowConfirmation(false)
    }

    const { requiredFieldsFilled } = getContactLogRequestedFields()
    const isAllRequiredFieldsFilled =
        requiredFieldsFilled.length && requiredFieldsFilled.every((el) => el)
    const isStatusEnable =
        !blockStatusChange && (isAllRequiredFieldsFilled || !requiredFieldsFilled.length)

    return (
        <>
            {showConfirmation && (
                <Popup center>
                    <div className="row column center middle">
                        <div className="messageText">
                            <p>
                                Setting your next status will clear all your contacts currently in
                                After Contact Work.
                            </p>
                            <p> Are you sure you want to do this?</p>
                        </div>
                        <div className="buttonWrapper">
                            <Button className="sm-mar-top" styling="primary" onClick={onConfirm}>
                                Yes
                            </Button>

                            <Button className="sm-mar-top" styling="primary" onClick={onCancel}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Popup>
            )}

            <div className="user-details">
                <h1 className="sa-username">{name || 'Loading...'}</h1>

                <div className="row middle">
                    {hasUser ? (
                        !isStatusEnable ? (
                            <UserStatus disabled acwConnections={acwConnections} />
                        ) : (
                            <div className="sa-userheader-right">
                                <TriggeredPopup
                                    className="user-status-popup"
                                    closeOnClickOutside
                                    ref={popupRef}
                                    noShadow
                                    trigger={
                                        <Button aria-labelledby="status">
                                            <UserStatus />
                                        </Button>
                                    }
                                >
                                    <UserStatusOptions
                                        logout={() => dispatch(logout())}
                                        states={states || []}
                                        currentStatusName={currentStatusName || ''}
                                        setUserState={(newStatus) => {
                                            setStatus(newStatus)
                                            setUserState(newStatus, true)
                                        }}
                                        changingState={changingState}
                                        hideOfflineStatus={hideOfflineStatus}
                                        hideLogout={
                                            !!contact && !Boolean(contact?.disconnectTimestamp)
                                        }
                                    />
                                </TriggeredPopup>
                            </div>
                        )
                    ) : (
                        <Popup center>
                            <div className="sa-logout">
                                <h2 className="md-mar-bottom">Checking for login...</h2>
                                <p>
                                    A new tab should have opened allowing you to login. If not,
                                    click the link below and log in there.
                                </p>
                                <Button styling="primary">
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={ccpURL + '/changeStatus'}
                                    >
                                        LOG IN
                                    </a>
                                </Button>
                                <p>
                                    (If you have logged in and this message is still here, try
                                    refreshing the page)
                                </p>
                            </div>
                        </Popup>
                    )}
                    {currentStatusName === 'End of Shift' ? (
                        <Popup center>
                            <div className="sa-logout">
                                <h2 className="md-mar-bottom">End of Shift</h2>
                                <h4>Hi {name}, your status is currently 'end of shift'</h4>
                                <p>
                                    If you are done for the day, click the logout link below which
                                    opens in a new tab, then click 'logout' at the bottom of the new
                                    page.
                                </p>
                                <p>
                                    Click 'start shift' to start work today, your status will be set
                                    to 'available'.
                                </p>
                                <Button styling="primary" onClick={() => dispatch(setAvailable())}>
                                    Start Shift
                                </Button>
                                <span>&nbsp;</span>
                                <Button styling="secondary">
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={ccpURL + '/changeStatus'}
                                    >
                                        LOGOUT
                                    </a>
                                </Button>
                                <p>
                                    (If the start shift button doesn't work, you may need to refresh
                                    this tab)
                                </p>
                            </div>
                        </Popup>
                    ) : null}
                    {agentDirectory && <AgentsDirectory />}
                    <Announcements />
                    <HandsUp />
                </div>
            </div>
        </>
    )
}

export default UserDetails
