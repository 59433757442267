import { Button } from '@missionlabs/smartagent-app-components'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CallState from 'store/call/call.state'
import { IChat } from 'store/chat/chat.state'
import { ITask } from 'store/tasks/tasks.state'

import classNames from 'classnames'
import useApp from 'hooks/redux/useApp'
import useChatConnections from 'hooks/redux/useChatConnections'
import useUser from 'hooks/redux/useUser'
import useGetDirectoryContact from 'hooks/useGetDirectoryContact'
import Answer from 'images/icon-call-answer.svg'
import Decline from 'images/icon-call-decline.svg'
import AnswerChat from 'images/icon-chat-accept.svg'
import DeclineChat from 'images/icon-chat-decline.svg'
import AnswerEmail from 'images/icon-email-accept.svg'
import DeclineEmail from 'images/icon-email-decline.svg'
import logger from 'logger'
import { ClipLoader } from 'react-spinners'
import { acceptCall, rejectCall } from 'store/call/call.actions'
import { acceptChat, declineChat, setSelectedChat } from 'store/chat/chat.actions'
import { selectContactSubType } from 'store/contact/contact.selectors'
import { setRedirect } from 'store/global/global.actions'
import { acceptTask, declineTask } from 'store/tasks/tasks.actions'
import { capitaliseStr } from 'utils'

interface Props {
    active?:
        | { channel: 'VOICE'; call: CallState }
        | { channel: 'CHAT'; chat: IChat }
        | { channel: 'TASK'; task: ITask }
}

export const IncomingChat: React.FC<IChat> = ({ customerName, id, queueName, channel }) => {
    const dispatch = useDispatch()

    const chats = useChatConnections()
    const hasActiveChat = chats.find((c) => c.status === connect.ContactStateType.CONNECTED)

    const accept = () => {
        dispatch(acceptChat(id))

        if (!hasActiveChat) {
            dispatch(setSelectedChat(id))
        }
    }

    const decline = () => {
        dispatch(declineChat(id))
    }

    const chatDescription = `Queue: ${queueName}`

    return (
        <div className="sa-incoming-contact-wrapper">
            <div className="sa-incoming-contact">
                <div className="sa-incoming-contact-details">
                    <h3 title={customerName}>{customerName}</h3>
                    <p title={chatDescription}>{chatDescription}</p>
                </div>
                <Button className="sa-incoming-answer" onClick={accept}>
                    <img src={channel === 'EMAIL' ? AnswerEmail : AnswerChat} alt="Accept" />
                </Button>
                <Button className="sa-incoming-decline" onClick={decline}>
                    <img src={channel === 'EMAIL' ? DeclineEmail : DeclineChat} alt="Decline" />
                </Button>
            </div>
        </div>
    )
}

export const IncomingTask: React.FC<ITask> = ({
    id,
    channel,
    accepting,
    name,
    description,
    attributes,
}) => {
    const dispatch = useDispatch()

    const accept = () => {
        dispatch(acceptTask(id))
    }

    const decline = () => {
        dispatch(declineTask(id))
    }

    const taskDisplayName = attributes?.['sa-task-display-name']?.value || name
    const taskDisplayDescription = attributes?.['sa-task-display-description']?.value || description

    return (
        <div className="sa-incoming-contact-wrapper">
            <div className="sa-incoming-contact">
                <div className="sa-incoming-contact-details">
                    <h3 title={taskDisplayName}>{taskDisplayName}</h3>
                    <p title={taskDisplayDescription}>{taskDisplayDescription}</p>
                </div>
                <Button className="sa-incoming-answer" onClick={accept}>
                    {accepting ? (
                        <ClipLoader size={16} color="#fff" />
                    ) : (
                        <img src={channel === 'EMAIL' ? AnswerEmail : AnswerChat} alt="Accept" />
                    )}
                </Button>
                <Button className="sa-incoming-decline" onClick={decline}>
                    <img src={channel === 'EMAIL' ? DeclineEmail : DeclineChat} alt="Decline" />
                </Button>
            </div>
        </div>
    )
}

export const IncomingVoice: React.FC<CallState> = ({ number, direction }) => {
    const dispatch = useDispatch()

    const { softphoneEnabled, softphoneAutoAccept } = useUser() ?? {}

    const contactSubType = useSelector(selectContactSubType)
    const isWebCall = contactSubType === 'connect:WebRTC'

    const [, name] = useGetDirectoryContact(number)

    // thameswater prod debugging
    const { ID } = useApp()
    useEffect(() => {
        if (ID === 'tw' && !softphoneEnabled) {
            logger.warning('Thames Water Softphone Disabled UI Alert')
        }
    }, [ID, softphoneEnabled])

    const answer = () => {
        dispatch(acceptCall())
        dispatch(setRedirect('/'))
    }

    const decline = () => {
        dispatch(rejectCall())
    }

    const acceptButtonClassName = classNames('sa-incoming-answer', {
        'sa-incoming-answer--last-button': softphoneAutoAccept,
    })

    const voiceDisplayName = name?.trim() || number
    const voiceDisplayDescription = capitaliseStr(direction)

    return (
        <div className="sa-incoming-contact-wrapper">
            <div className="sa-incoming-contact">
                <div className="sa-incoming-contact-details">
                    {isWebCall && <h3>Web call</h3>}
                    <h3 title={voiceDisplayName}>{voiceDisplayName}</h3>
                    <p title={voiceDisplayDescription}>{voiceDisplayDescription}</p>
                </div>
                {softphoneEnabled ? (
                    <>
                        <Button className={acceptButtonClassName} onClick={answer}>
                            <img src={Answer} alt="Answer" />
                        </Button>
                        {!softphoneAutoAccept && (
                            <Button className="sa-incoming-decline" onClick={decline}>
                                <img src={Decline} alt="Decline" />
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Button className="sa-incoming-decline" onClick={decline}>
                            <img src={Decline} alt="Decline" />
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}

const IncomingContact: React.FC<Props> = ({ active }) => {
    if (active?.channel === 'VOICE') return <IncomingVoice {...active.call} />
    if (active?.channel === 'CHAT') return <IncomingChat {...active.chat} />
    if (active?.channel === 'TASK') return <IncomingTask {...active.task} />
    return null
}

export default IncomingContact
